<template>
  <!-- 引导页 -->
  <div id="guidepage" ref="menu">
    <div class="mobilheader_box" style="text-align: left">
      
      <div class="sales-intercept mask">
        <div class="sales-intercept-box">
          <div class="sales-intercept-close">
            <img src="@/assets/mobile/v0.1/images/mobil_header/close.png" alt="" />
          </div>
          <div class="sales-intercept-title">
            <div class="sales-intercept-icon">
              <img src="@/assets/mobile/v0.1/images/mobil_header/tips.png" alt="" />
            </div>
            <h3>抱歉，您所使用的账号暂未绑定对应的产品！</h3>
          </div>
          <div class="sales-intercept-describe">
            请联系贵司企业管理员，为您的账号绑定对应的产品。若需购买产品，
            请拨打
            <a class="number" href="javascript:;">400-6789-189</a>
            免费咨询
          </div>
          <a href="javascript:;">
            <div class="sales-intercept-butt">确定</div>
          </a>
        </div>
      </div>

      <div class="Bottom-remain" :class="{ isBlock: isActive }">
        <a href="javascript:;" rel="nofollow" @click="openQuestion('我要提问')">
          <div class="Bottom-remain-left">
            <img src="@/assets/mobile/v0.1/images/mobil_header/zx_icon.png" />我要提问
          </div>
        </a>
        <a href="javascript:;" @click="qidai('立即签约')">
          <div class="Bottom-remain-right">
            <!-- <img
              src="@/assets/mobile/v0.1/images/mobil_header/rx_icon.png"
            /> -->
            立即签约
          </div>
        </a>
      </div>
    </div>
    <!--banner-->
    <div class="product_xc_banner">
      <van-swipe v-if="offsetWidth < 1024" style="width: 100%;height: 100%;" :autoplay="3000">
        <van-swipe-item v-for="(item,index) in imgItems" :key="index">
            <img class="banner_phone" @click="changBanner(item)" :src="item.mobileImageUrl"
             alt="" style="width: 100%;height: 100%;" />
        </van-swipe-item>
        <van-swipe-item>
          <img fit="cover" class="banner_phone"
            src="../../assets/mobile/v0.1/images/mobil_product_xc/sass01.png" alt="" style="width: 100%;height: 100%;" />
          <!-- <img v-if="offsetWidth < 1024" class="banner_phone" src="@/assets/images/shouyepc1.png" alt=""
            style="width: 100%;" /> -->
          <div class="text" >
            <div class="w1440">
              <div class="h1">
                <div class="fnt_42 tit">
                  道可维斯·数智文档
                  <br />
                </div>
                <div class="fnt_42 tit">
                  <b>企业传统文档协作模式颠覆者</b>
                </div>
              </div>
              <div class="banner-text fnt_18">
                杜绝“三多四跨”环境下
                <br />
                企业供应链文档协作“散漏错慢”
                <br />

              </div>
              <div class="product_xc_banner_link">
                <a href="javascript:;" @click="openRegisterPage('预约演示')">预约演示</a>
                <a href="javascript:;" @click="openVideo('介绍视频')">介绍视频</a>
              </div>
            </div>
          </div>
          <div class="text" v-if="offsetWidth > 1024">
            <div class="w1440 wow fadeInUp animated">
              <div class="h1">
                <div class="fnt_42 tit">
                  道可维斯·数智文档
                  <br />
                </div>
                <div class="fnt_42 tit">
                  <b>企业传统文档协作模式颠覆者</b>
                </div>
              </div>
              <div class="banner-text fnt_18">
                杜绝“三多四跨”环境下
                <br />
                企业供应链文档协作“散漏错慢”
                <br />
                <br />
                <br />
                <br />
              </div>
              <div class="product_xc_banner_link">
                <a href="javascript:;" @click="openRegisterPage('预约演示')">预约演示</a>
                <a href="javascript:;" @click="openVideo('介绍视频')">介绍视频</a>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
      <div v-if="offsetWidth > 1024">
        <div class="block" style="width: 100vw;height: 13rem;">
          <el-carousel trigger="click" height="13rem">
            <el-carousel-item v-for="(item,index) in imgItems" :key="index" @click="changBanner(item)">
              <img class="banner_phone" :src="item.imageUrl | aa" @click="changBanner(item)" alt=""
                style="width: 100%;height: 100%" />
            </el-carousel-item>
            <el-carousel-item>
              <img class="banner_phone" src="@/assets/images/shouyepc1.png" alt="" style="width: 100%;height: 100%" />
              <div class="text" v-if="offsetWidth < 1024">
                <div class="w1440">
                  <div class="h1">
                    <div class="fnt_42 tit">
                      道可维斯·数智文档
                      <br />
                    </div>
                    <div class="fnt_42 tit">
                      <b>企业传统文档协作模式颠覆者</b>
                    </div>
                  </div>
                  <div class="banner-text fnt_18">
                    杜绝“三多四跨”环境下
                    <br />
                    企业供应链文档协作“散漏错慢”
                    <br />

                  </div>
                  <div class="product_xc_banner_link">
                    <a href="javascript:;" @click="openRegisterPage('预约演示')">预约演示</a>
                    <a href="javascript:;" @click="openVideo('介绍视频')">介绍视频</a>
                  </div>
                </div>
              </div>
              <div class="text" v-if="offsetWidth > 1024">
                <div class="w1440 wow fadeInUp animated">
                  <div class="h1">
                    <div class="fnt_42 tit">
                      道可维斯·数智文档
                      <br />
                    </div>
                    <div class="fnt_42 tit">
                      <b>企业传统文档协作模式颠覆者</b>
                    </div>
                  </div>
                  <div class="banner-text fnt_18">
                    杜绝“三多四跨”环境下
                    <br />
                    企业供应链文档协作“散漏错慢”
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div class="product_xc_banner_link">
                    <a href="javascript:;" @click="openRegisterPage('预约演示')">预约演示</a>
                    <a href="javascript:;" @click="openVideo('介绍视频')">介绍视频</a>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

      </div>



    </div>
    <!-- 案例详情 -->
    <!-- PC >1024 -->
    <div class="anli1 EASC-anli">
      <div class="title pcshow">
        <p><span class="wordColor">16年</span>的耕耘，道可维斯已是国内 <span class="wordColor">200</span>多家大中型集团和企业的数智文档解决方案提供商 <br />
          已持续安全稳定的运营超过<span class="wordColor">113880</span>个小时。</p>
      </div>
      <!-- <div class="title1 pcshow">
        全行业全场景覆盖，道可维斯实现的远比现实中更多……… <br /> （供应商付款，员工报销，门店收款核算，客户收货，设计拆图，工程项目，员工成长，企业档案，电子发票，合同管理…）
      </div> -->
      <div class="cont swiper-relative pcshow">
        <!-- <slider
            class="swiper-wrapper"
            ref="slider"
            :options="options"
            @slide="slide"
            @tap="onTap"
            @init="onInit"
            v-if="offsetWidth<1000"
          >
            <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png"
                      alt="e企拆表"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      e企拆表
                    </div>
                    <div class="epm_des">
                      一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                    </div>
                    <el-button
                      class=""
                      @click="openProductView('e企拆表')"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png"
                      alt="e企验票"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      e企验票
                    </div>
                    <div class="epm_des">
                      一个没有卡顿的验票工具，让报账单顺利完结<br />
                    </div>
                    <el-button
                      class=""
                      @click="openProductView('e企验票')"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem>
            <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="电子会计档案"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      电子会计档案
                    </div>
                    <div class="epm_des">
                      影像技术实现电子档案归档与关联自动化，帮助企业自动建立“票、单、证、账、表”的电子档案，满足税务审计的合规要求。<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/all_archives.png"
                      alt="全电子档案产品"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      全电子档案产品
                    </div>
                    <div class="epm_des">
                      从“结果、静态、备用”到“过程、动态、时用”的综合电子档案管理<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem>
          </slider>  -->
        <!-- <div class="cont-1" v-else> -->
        <!-- 11个产品 -->
        <div class="cont-1" style="display:flex;justify-content:space-evenly;border-bottom:1px solid #e1e1e1;padding-bottom:10px">
          <!-- <div class="cont-1-div" :style="{
            padding: '20px 25px',
            'background-color': '#fff',
            height: '190px',
            'margin-bottom': '10px',
          }">
            <a href="javascript:;">
              <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                <img :style="{ width: '30px', height: '30px' }"
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png" alt="e企拆表" />
              </div>
              <div class="hover-content-title1">
                e企拆表
              </div>
              <div class="text">
                <div class="hover-content">
                  <div class="epm_des">
                    一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                  </div>
                  <el-button class="" @click="openProductView('e企拆表')" :style="{
                    width: '100px',
                    'line-height': '30px',
                    height: '30px',
                    'border-radius': '4px',
                  }">
                    了解详情
                  </el-button>
                </div>
              </div>
            </a>
          </div>
          <div class="cont-1-div" :style="{
            padding: '20px 25px',
            'background-color': '#fff',
            height: '190px',
            'margin-bottom': '10px',
          }">
            <a href="javascript:;">
              <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                <img :style="{ width: '30px', height: '30px' }"
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png" alt="e企档案" />
              </div>
              <div class="hover-content-title1">
                e企档案
              </div>
              <div class="text">
                <div class="hover-content">
                  <div class="epm_des">
                    一款让交易内容+证明内容+说服内容，在企业跨业务跨组织跨系统协作中，集中共享高速流转的软件产品。<br />
                  </div>
                  <el-button class="" @click="openProductView('e企档案')" :style="{
                    width: '100px',
                    'line-height': '30px',
                    height: '30px',
                    'border-radius': '4px',
                  }">
                    了解详情
                  </el-button>
                </div>
              </div>
            </a>
          </div>
          <div class="cont-1-div" :style="{
            padding: '20px 25px',
            'background-color': '#fff',
            height: '190px',
            'margin-bottom': '10px',
          }">
            <a href="javascript:;">
              <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                <img :style="{ width: '30px', height: '30px' }"
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png" alt="e企签收" />
              </div>
              <div class="hover-content-title1">
                e企签收
              </div>
              <div class="text">
                <div class="hover-content">
                  <div class="epm_des">
                    一款对物流签收工作，从发货在途，到抵达签收，再到收入确认的过程做了业务支撑与监控，帮助企业实现及时确认收入的软件产品。<br />
                  </div>
                  <el-button class="" @click="openProductView('e企签收')" :style="{
                    width: '100px',
                    'line-height': '30px',
                    height: '30px',
                    'border-radius': '4px',
                  }">
                    了解详情
                  </el-button>
                </div>
              </div>
            </a>
          </div>
          <div class="cont-1-div" :style="{
            padding: '20px 25px',
            'background-color': '#fff',
            height: '190px',
            'margin-bottom': '10px',
          }">
            <a href="javascript:;">
              <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                <img :style="{ width: '30px', height: '30px' }"
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png" alt="电子影像" />
              </div>
              <div class="hover-content-title1">
                电子影像
              </div>
              <div class="text">
                <div class="hover-content">
                  <div class="epm_des">
                    一款借助影像共享技术，助力企业业财税档融合，助力财务升级的必备系统。<br />
                  </div>
                  <el-button class="" @click="openProductView('电子影像')" :style="{
                    width: '100px',
                    'line-height': '30px',
                    height: '30px',
                    'border-radius': '4px',
                  }">
                    了解详情
                  </el-button>
                </div>
              </div>
            </a>
          </div> -->
          <!-- <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png"
                      alt="e企验票"
                    />
                  </div>
                  <div class="hover-content-title1">
                    e企验票
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         一个没有卡顿的验票工具，让报账单顺利完结<br />
                      </div>
                      <el-button
                        class=""
                        @click="openProductView('e企验票')"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="港口计费账单整合"
                    />
                  </div>
                  <div class="hover-content-title1">
                    港口计费账单整合
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         港口计费单据附件关联、整理、合并、打印，打破计费低效困局<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="财务电子影像"
                    />
                  </div>
                  <div class="hover-content-title1">
                    财务电子影像
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         实现业财系统与影像系统的互联互通，形成业财流程带影像审批，规避审批风险，为财务档案提供合规性前提保障<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
             <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="全电子档案系统"
                    />
                  </div>
                  <div class="hover-content-title1">
                    全电子档案系统
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         从“结果、静态、备用”到“过程、动态、时用”的综合电子档案管理<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="电子会计档案系统"
                    />
                  </div>
                  <div class="hover-content-title1">
                    电子会计档案系统
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         实现电子档案归档与关联自动化，帮助企业自动建立“票、单、证、账、表”的电子档案，满足税务审计的合规要求<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="智能报销"
                    />
                  </div>
                  <div class="hover-content-title1">
                    智能报销
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         提供发票及附件的采集、查重、查验、报账及业财带影像审批的支持服务，实现员工自助报销省时，财务审批省力<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="发票池"
                    />
                  </div>
                  <div class="hover-content-title1">
                    发票池
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         通过发票台账数据的集中管理，横向连接业务、财务、税务三方系统，通过发票实现企业税务自查，保障进项税务0风险<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
             <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="供应商无纸化对账"
                    />
                  </div>
                  <div class="hover-content-title1">
                    供应商无纸化对账
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         告别纸质对账单，月底电子供应商对账与付款合规<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
             <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="企业收发货管理"
                    />
                  </div>
                  <div class="hover-content-title1">
                    企业收发货管理
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                        <span>业务</span>  
                        <span>行业</span>  
                        <span>通用型</span>  
                      </div>
                      <div class="epm_des">
                         客户签收回执回收提速，加快企业回款周期<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div> -->
            
         <div class="cont-1-div" :style="{
            padding: '20px 25px',
            backgroundColor: currentProduct?.seriesName == item.seriesName?'#e4f1ff':'#fff',
            height: '100px',
            'margin-bottom': '10px',
            width:'31% !important'
          }" v-for="(item,index) in productList" :key="index" @click="currentProduct = item">
            <a href="javascript:;">
              <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                <img :style="{ width: '30px', height: '30px' }"
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png" />
              </div>
              <div class="hover-content-title1">
                {{item.seriesName}}
              </div>
              <div style="margin-top:10px">
                {{item.seriesDesc}}
              </div>
              <!-- <div class="text">
                <div class="hover-content">
                  <div class="epm_des">
                    {{item.seriesDesc}}<br />
                  </div>
                  <el-button class="" @click="openProductView('e企拆表')" :style="{
                    width: '100px',
                    'line-height': '30px',
                    height: '30px',
                    'border-radius': '4px',
                  }">
                    了解详情
                  </el-button>
                </div>
              </div> -->
            </a>
          </div>
          
        </div>
        <div style="margin-top:20px;border-radius:10px;padding:10px;background: #e4f1ff;">
            <el-carousel height="350px" indicator-position="none">
              <el-carousel-item v-for="(item,index) in currentProduct.softwareList" :key="'software'+index">
                <div style="display:flex">
                  <el-carousel height="350px" style="width:50%" indicator-position="none" arrow="never">
                    <el-carousel-item v-for="(itemi,indexi) in item.imageList" :key="'image'+indexi">
                      <img style="width:100%" :src="itemi" />
                    </el-carousel-item>
                  </el-carousel>
                  <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:50%">
                    <div style="width:85%">
                      <div style="font-size:20px;font-weight:600">{{item.name}}</div>
                      <div style="font-size:14px;margin:15px 0px 100px;line-height:50px;height:50px">{{item.desc}}</div>
                      <div>
                        <!-- <el-button class="" @click="openProductView('e企拆表')" :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }">
                          申请使用
                        </el-button> -->
                        <el-button class="" @click="openProductPath(item)" :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                          backgroundColor:'#2788ee',
                          color:'#fff'
                        }">
                          了解详情
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div style="width:100%;text-align:center;margin-top:25px">
              <el-button class="" @click="openProductPath(currentProduct)" :style="{
                  width: '150px',
                  'line-height': '35px',
                  height: '35px',
                  'border-radius': '4px',
                }">
                  
                  查看更多详情
                </el-button>
            </div>
        </div>
        <!-- <ul class="swiper-wrapper"> 
            <li class="swiper-slide">
              <a href="#/allProduct">
                <div class="img">
                  <img
                    src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/051403227sx8.png"
                    alt="e企拆表"
                  />
                </div>
                <div class="text">
                  <div class="fnt_22 epm_tit">e企拆表</div>
                  <div class="epm_des">
                    一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                  </div>
                  <div class="epm_more" @click="openProductView('e企拆表')">
                    了解详情
                  </div>
                </div>
              </a>
            </li>
            <li class="swiper-slide">
              <a href="https://www.kingdee.com/products/cosmic_finance.html">
                <div class="img">
                  <img
                    src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05140236a62e.png"
                    alt="e企验票"
                  />
                </div>
                <div class="text">
                  <div class="fnt_22 epm_tit">e企验票</div>
                  <div class="epm_des">
                    一个没有卡顿的验票工具，让报账单顺利完结<br />
                  </div>
                  <div class="epm_more" @click="openProductView('e企验票')">
                    了解详情
                  </div>
                </div>
              </a>
            </li>
          </ul> -->
      </div>
      <div class="swiper-pagination"></div>

      
    </div>
    <div class="rlv-product mobielPhone" style="background-color: #fff">
      <div class="w1440">
        <!-- 移动端 -->
        <div class="cont swiper-relative mobielPhone">
          <!-- <slider class="swiper-wrapper cont-swiper" ref="slider" :options="options" @slide="slide" @tap="onTap"
            @init="onInit" v-if="offsetWidth < 1024">
            <slideritem class="swiper-slide" :style="{
              'white-space': 'normal',
              'text-align': 'left',
              display: 'inline-block',
            }">
              <div :style="{
                padding: '20px 25px',
                'background-color': '#fff',
                height: '190px',
                width: '100%',
                'margin-bottom': '10px',
              }">
                <a href="javascript:;">
                  <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                    <img :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png" alt="e企拆表" />
                  </div>
                  <div class="text">
                    <div class="fnt_22 epm_tit" :style="{ margin: '10px 0 20px 40px' }">
                      e企拆表
                    </div>
                    <div class="epm_des">
                      一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                    </div>
                    <el-button class="" @click="openProductView('e企拆表')" :style="{
                      width: '100px',
                      'line-height': '30px',
                      height: '30px',
                      'border-radius': '4px',
                    }">
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div :style="{
                padding: '20px 25px',
                'background-color': '#fff',
                height: '190px',
                width: '100%',
                'margin-bottom': '10px',
              }">
                <a href="javascript:;">
                  <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                    <img :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png" alt="e企档案" />
                  </div>
                  <div class="text">
                    <div class="fnt_22 epm_tit" :style="{ margin: '10px 0 20px 40px' }">
                      e企档案
                    </div>
                    <div class="epm_des">
                      一款让交易内容+证明内容+说服内容，在企业跨业务跨组织跨系统协作中，集中共享高速流转的软件产品。<br />
                    </div>
                    <el-button class="" @click="openProductView('e企档案')" :style="{
                      width: '100px',
                      'line-height': '30px',
                      height: '30px',
                      'border-radius': '4px',
                    }">
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div> -->

              <!-- <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png"
                      alt="e企验票"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      e企验票
                    </div>
                    <div class="epm_des">
                      一个没有卡顿的验票工具，让报账单顺利完结<br />
                    </div>
                    <el-button
                      class=""
                      @click="openProductView('e企验票')"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div> -->
            <!-- </slideritem> -->
            <!-- <slideritem class="swiper-slide" :style="{
              'white-space': 'normal',
              'text-align': 'left',
              display: 'inline-block',
            }">
              <div :style="{
                padding: '20px 25px',
                'background-color': '#fff',
                height: '190px',
                width: '100%',
                'margin-bottom': '10px',
              }">
                <a href="javascript:;">
                  <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                    <img :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png" alt="e企签收" />
                  </div>
                  <div class="text">
                    <div class="fnt_22 epm_tit" :style="{ margin: '10px 0 20px 40px' }">
                      e企签收
                    </div>
                    <div class="epm_des">
                      一款对物流签收工作，从发货在途，到抵达签收，再到收入确认的过程做了业务支撑与监控，帮助企业实现及时确认收入的软件产品。<br />
                    </div>
                    <el-button class="" @click="openProductView('e企签收')" :style="{
                      width: '100px',
                      'line-height': '30px',
                      height: '30px',
                      'border-radius': '4px',
                    }">
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div :style="{
                padding: '20px 25px',
                'background-color': '#fff',
                height: '190px',
                width: '100%',
                'margin-bottom': '10px',
              }">
                <a href="javascript:;">
                  <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                    <img :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png" alt="电子影像" />
                  </div>
                  <div class="text">
                    <div class="fnt_22 epm_tit" :style="{ margin: '10px 0 20px 40px' }">
                      电子影像
                    </div>
                    <div class="epm_des">
                      一款借助影像共享技术，助力企业业财税档融合，助力财务升级的必备系统。<br />
                    </div>
                    <el-button class="" @click="openProductView('电子影像')" :style="{
                      width: '100px',
                      'line-height': '30px',
                      height: '30px',
                      'border-radius': '4px',
                    }">
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem> -->
            <!-- <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="港口计费账单整合"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    港口计费账单整合
                    </div>
                    <div class="epm_des">
                      港口计费单据附件关联、整理、合并、打印，打破计费低效困局<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="财务电子影像"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    财务电子影像
                    </div>
                    <div class="epm_des">
                      实现业财系统与影像系统的互联互通，形成业财流程带影像审批，规避审批风险，为财务档案提供合规性前提保障<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem>
            <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/all_archives.png"
                      alt="全电子档案产品"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      全电子档案产品
                    </div>
                    <div class="epm_des">
                      从“结果、静态、备用”到“过程、动态、时用”的综合电子档案管理<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="电子会计档案"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                      电子会计档案
                    </div>
                    <div class="epm_des">
                      影像技术实现电子档案归档与关联自动化，帮助企业自动建立“票、单、证、账、表”的电子档案，满足税务审计的合规要求。<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem>
            <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
            <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="智能报销"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    智能报销
                    </div>
                    <div class="epm_des">
                      提供发票及附件的采集、查重、查验、报账及业财带影像审批的支持服务，实现员工自助报销省时，财务审批省力<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/all_archives.png"
                      alt="发票池"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    发票池
                    </div>
                    <div class="epm_des">
                      通过发票台账数据的集中管理，横向连接业务、财务、税务三方系统，通过发票实现企业税务自查，保障进项税务0风险<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem>
            <slideritem
              class="swiper-slide"
              :style="{
                'white-space': 'normal',
                'text-align': 'left',
                display: 'inline-block',
              }"
            >
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="供应商无纸化对账"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    供应商无纸化对账
                    </div>
                    <div class="epm_des">
                      告别纸质对账单，月底电子供应商对账与付款合规<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
              <div
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="企业收发货管理"
                    />
                  </div>
                  <div class="text">
                    <div
                      class="fnt_22 epm_tit"
                      :style="{ margin: '10px 0 20px 40px' }"
                    >
                    企业收发货管理
                    </div>
                    <div class="epm_des">
                      客户签收回执回收提速，加快企业回款周期<br />
                    </div>
                    <el-button
                      class=""
                      @click="qidai"
                      :style="{
                        width: '100px',
                        'line-height': '30px',
                        height: '30px',
                        'border-radius': '4px',
                      }"
                    >
                      了解详情
                    </el-button>
                  </div>
                </a>
              </div>
            </slideritem> -->
          <!-- </slider> -->
          <!-- <div class="cont-1" v-else> 
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png"
                      alt="e企拆表"
                    />
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企拆表
                      </div>
                      <div class="epm_des">
                        一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                      </div>
                      <el-button
                        class=""
                        @click="openProductView('e企拆表')"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_yanpiao.png"
                      alt="e企验票"
                    />
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        e企验票
                      </div>
                      <div class="epm_des">
                        一个没有卡顿的验票工具，让报账单顺利完结<br />
                      </div>
                      <el-button
                        class=""
                        @click="openProductView('e企验票')"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/dianzi_archives.png"
                      alt="电子会计档案"
                    />
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        电子会计档案
                      </div>
                      <div class="epm_des">
                        影像技术实现电子档案归档与关联自动化，帮助企业自动建立“票、单、证、账、表”的电子档案，满足税务审计的合规要求。<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
            <div class="cont-1-div"
                :style="{
                  padding: '20px 25px',
                  'background-color': '#fff',
                  height: '190px',
                  'margin-bottom': '10px',
                }"
              >
                <a href="javascript:;">
                  <div
                    class="img"
                    :style="{ width: '30px', height: '30px', float: 'left' }"
                  >
                    <img
                      :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/all_archives.png"
                      alt="全电子档案产品"
                    />
                  </div>
                  <div class="text">
                    <div class="hover-content">
                      <div class="hover-content-title">
                        全电子档案产品
                      </div>
                      <div class="epm_des">
                        从“结果、静态、备用”到“过程、动态、时用”的综合电子档案管理<br />
                      </div>
                      <el-button
                        class=""
                        @click="qidai"
                        :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }"
                      >
                        了解详情
                      </el-button>
                    </div>
                  </div>
                </a>
            </div>
          </div>
          <ul class="swiper-wrapper">
            <li class="swiper-slide">
              <a href="#/allProduct">
                <div class="img">
                  <img
                    src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/051403227sx8.png"
                    alt="e企拆表"
                  />
                </div>
                <div class="text">
                  <div class="fnt_22 epm_tit">e企拆表</div>
                  <div class="epm_des">
                    一款提升制造业拆图效率的工具，让拆图效率提高200%<br />
                  </div>
                  <div class="epm_more" @click="openProductView('e企拆表')">
                    了解详情
                  </div>
                </div>
              </a>
            </li>
            <li class="swiper-slide">
              <a href="https://www.kingdee.com/products/cosmic_finance.html">
                <div class="img">
                  <img
                    src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05140236a62e.png"
                    alt="e企验票"
                  />
                </div>
                <div class="text">
                  <div class="fnt_22 epm_tit">e企验票</div>
                  <div class="epm_des">
                    一个没有卡顿的验票工具，让报账单顺利完结<br />
                  </div>
                  <div class="epm_more" @click="openProductView('e企验票')">
                    了解详情
                  </div>
                </div>
              </a>
            </li>
          </ul> -->
        <slider class="swiper-wrapper cont-swiper" ref="slider" :options="options" @slide="slide" @tap="onTap"
            @init="onInit" v-if="offsetWidth < 1024">
            <slideritem class="swiper-slide" :style="{
              'white-space': 'normal',
              'text-align': 'left',
              display: 'inline-block',
            }"
            v-for="(item,index) in productList" :key="index" >
              <div :style="{
                padding: '20px 25px',
                'background-color': '#fff',
                height: '120px',
                width: '100%',
                'margin-bottom': '10px',
              }" @click="currentProduct = item">
                <a href="javascript:;">
                  <div class="img" :style="{ width: '30px', height: '30px', float: 'left' }">
                    <img :style="{ width: '30px', height: '30px' }"
                      src="@/assets/mobile/v0.1/images/mobil_product_xc/e_chaitu.png" alt="e企拆表" />
                  </div>
                  <div class="text">
                    <div class="fnt_22 epm_tit" :style="{ margin: '10px 0 20px 40px' }">
                      {{item.seriesName}}
                    </div>
                    <div class="epm_des">
                      {{item.seriesDesc}}<br />
                    </div>
                    <!-- <el-button class="" @click="openProductView('e企拆表')" :style="{
                      width: '100px',
                      'line-height': '30px',
                      height: '30px',
                      'border-radius': '4px',
                    }">
                      了解详情
                    </el-button> -->
                  </div>
                </a>
              </div>
            </slideritem>
        </slider>
        <div  style="margin-top:10px;border-radius:10px;padding:10px;background: #e4f1ff;">
            <el-carousel height="100px" indicator-position="none">
              <el-carousel-item v-for="(item,index) in currentProduct.softwareList" :key="'software'+index">
                <div style="display:flex">
                  <el-carousel height="100px" style="width:50%" indicator-position="none" arrow="never">
                    <el-carousel-item v-for="(itemi,indexi) in item.imageList" :key="'image'+indexi">
                      <img style="width:100%" :src="itemi" />
                    </el-carousel-item>
                  </el-carousel>
                  <div style="display:flex;flex-direction:column;align-items:center;justify-content:center;width:50%">
                    <div style="width:80%">
                      <div style="font-size:15px;font-weight:600">{{item.name}}</div>
                      <div style="font-size:12px;margin:10px 0px">{{item.desc}}</div>
                      <div>
                        <!-- <el-button class="" @click="openProductView('e企拆表')" :style="{
                          width: '100px',
                          'line-height': '30px',
                          height: '30px',
                          'border-radius': '4px',
                        }">
                          申请使用
                        </el-button> -->
                        <el-button class=""  @click="openProductPath(item)" :style="{
                          width: '100px',
                          'line-height': '20px',
                          height: '20px',
                          'border-radius': '4px',
                          backgroundColor:'#2788ee',
                          color:'#fff',
                          fontSize:'12px'
                        }">
                          了解详情
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div style="width:100%;text-align:center;margin-top:15px">
              <el-button class=""  @click="openProductPath(currentProduct)" :style="{
                  width: '150px',
                  'line-height': '25px',
                  height: '25px',
                  'border-radius': '4px',
                }">
                  
                  查看更多详情
                </el-button>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        
      </div>
    </div>

    <!-- 简介概述 -->
    <div class="manage cloud-intro" style="background-color: #e1e9f3">
      <div class="w1440">
        <h2 class="fnt_32" :style="{ 'margin-bottom': '0', 'font-weight': '800' }">
          什么是道可维斯·数智文档？
        </h2>
        <p :style="{
          'font-size': '24px',
          'font-weight': '700',
          color: '#000',
          'margin-bottom': '42px',
          'text-align': 'center',
        }">
          数智文档是专注于“三多四跨”的数字化ECM！
        </p>
        <div class="fnt_16 manage_sum" :style="{ 'text-align': 'left', margin: '0 auto', width: '97%' }">
          <!-- <p :style="{ 'font-size': '24px','font-weight': '700', 'color':'#000','margin-bottom':'20px' }">数智文档是专注于“三多四跨”的数字化ECM！</p> -->

          <!-- <p class="wordColor" :style="{ 'margin-bottom': '8px' }">
            ECM（Enterprise Content Management）
          </p> -->

          <p class="pcp" :style="{ 'margin-bottom': '8px' }">
            <span class="wordColor">ECM（Enterprise Content Management）</span>
            即企业内容管理。ECM即是技术方案，也是管理方案，是后ERP时代，帮助企业提升高频文档内容在商业流程协作效率、质量以及合规性，而提出的全新解题思路。后ERP时代，企业的客户、供应商、员工、领导在商业协作中大量需要传递和审阅单据附件文档内容，在<span
              class="wordColor">跨时空、跨业务、跨组织、跨系统的“四跨”工作模式</span>下，“散漏错慢”的问题层出不穷，防不胜防。
          </p>
          <!-- <p :style="{ 'margin-bottom': '8px' }">
      
          </p> -->
          <p class="pcp" :style="{ 'margin-bottom': '8px' }">
            协作文档与内容的<span
              class="wordColor">“三多”特性（多介质→纸质与电子文档、多来源→外部通讯工具与内部软件系统、多媒体→文字图表网页数据库表音视频压缩包）</span>，更是压垮一线员工的最后一根稻草！文档与内容电子化+自动化+智能化是道可维斯·数智文档的技术能力，“三多四跨”环境下的文档内容数智化采集与集中、业务关联与规则校验是道可维斯·数智文档的设计理念。
            <!-- 协作文档与内容的“三多”特性（多介质→纸质与电子文档、多来源→外部通讯工具与内部软件系统、多媒体→文字图表网页数据库表音视频压缩包），更是压垮一线员工的最后一根稻草！ -->
          </p>
          <!-- <p :style="{ 'margin-bottom': '8px' }">
            
          </p> -->
          <p class="pcp" :style="{ 'margin-bottom': '8px' }">
            道可维斯，用数智文档链接你我他！
          </p>
          <p class="connectus" :style="{ 'margin-top': '40px', 'margin-bottom': '8px', 'text-align': 'center' }">
            <a class="huoqu_more" href="javascript:;" @click="openRegisterPage('联系我们')">即刻联系我们，获取免费咨询</a>
          </p>

          <br />
        </div>
        <!-- <slider
          class="EBC-intro-list"
          ref="slider"
          :options="options"
          @slide="slide"
          @tap="onTap"
          @init="onInit"
        >
          <slideritem
            class="clearfix swiper-wrapper"
            :style="{ 'white-space': 'normal', 'text-align': 'left' }"
          >
            <div class="swiper-slide">
              <div class="icon">
                <img
                  src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05134338t8l0.png"
                  alt="全方位支持"
                />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>消除“散乱”</b></h3>
                <p>
                  道可维斯创新性的数智文档采集器，通过自动抓取方式跨系统的单据与附件文档，替代员工快速实现离散文档的集中整合。
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="icon">
                <img
                  src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05134338t8l0.png"
                  alt="技术高新"
                />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>杜绝“遗漏”</b></h3>
                <p>
                  道可维斯·数智文档协作规则配置器，通过协作场景文档关联类型定义规则，帮助员工和领导杜绝协作文档提交和审阅遗漏。
                </p>
              </div>
            </div> </slideritem
          ><slideritem
            class="clearfix swiper-wrapper"
            :style="{ 'white-space': 'normal', 'text-align': 'left' }"
          >
            <div class="swiper-slide">
              <div class="icon">
                <img
                  src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05134338t8l0.png"
                  alt="安全可靠"
                />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>预防“错误”</b></h3>
                <p>
                  道可维斯·数智文档基于AI大数据技术的业务规则关联校验机器人，以90%以上的纠错准确率帮助员工和领导释放工作压力。
                </p>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="icon">
                <img
                  src="https://kingdeecms-1252177366.cos.ap-guangzhou.myqcloud.com/u/cms/www/202208/05134338t8l0.png"
                  alt="全场景覆盖"
                />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>告别“缓慢”</b></h3>
                <p>
                  道可维斯·数智文档革命性的业务集成调度软件中台，开启企业“三多四跨”离散文档流转“高速公路”，体验文档协作的极致快捷。
                </p>
              </div>
            </div>
          </slideritem>
          <div slot="loading">loading...</div>
        </slider> -->
        <!-- --------------------------- -->
        
      </div>
    </div>

    <!-- 相关产品-1170案例 -->
    <div class="rlv-product" style="background-color: #fff">
      <div class="w1440">
        <div class="title" :style="{ width: '100%' }">
          <h3 class="fnt_32">改变，从现在开始！</h3>
          <p class="pcp1" :style="{ color: '#575d6c', 'margin-top': '10px', }" v-if="offsetWidth > 1024">
            道可维斯·数智文档更简单、更轻便、更全面，以领先的公有云计算中心+私有化数据中心的混合云模式，确保你的数据安全。<br />助您提升业+财+税+档一体化协作效率、质量与成本的数字化竞争力！
          </p>
          <p class="pcp1" :style="{ color: '#575d6c', 'margin-top': '10px', 'text-align': 'left' }" v-else>
            道可维斯·数智文档更简单、更轻便、更全面，以领先的公有云计算中心+私有化数据中心的混合云模式，确保你的数据安全。助您提升业+财+税+档一体化协作效率、质量与成本的数字化竞争力！
          </p>


        </div>
        <div class="EBC-intro-list mobielPhone">
          
          <ul class="clearfix swiper-wrapper" style="flex-wrap: nowrap">
            <li class="swiper-slide">
              <div class="icon">
                <img src="@/assets/mobile/v0.1/images/mobil_product_xc/san.png" alt="技术领先" />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>消除“散”乱</b></h3>
                <p>
                  道可维斯创新性的数智文档采集器，通过自动抓取方式跨系统的单据与附件文档，替代员工快速实现离散文档的集中整合。
                </p>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="icon">
                <img src="@/assets/mobile/v0.1/images/mobil_product_xc/lou.png" alt="安全可信" />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>杜绝遗“漏”</b></h3>
                <p>
                  道可维斯·数智文档协作规则配置器，通过协作场景文档关联类型定义规则，帮助员工和领导杜绝协作文档提交和审阅遗漏。
                </p>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="icon">
                <img src="@/assets/mobile/v0.1/images/mobil_product_xc/cuo.png" alt="应用丰富" />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>预防“错”误</b></h3>
                <p>
                  道可维斯·数智文档基于AI大数据技术的业务规则关联校验机器人，以90%以上的纠错准确率帮助员工和领导释放工作压力。
                </p>
              </div>
            </li>
            <li class="swiper-slide">
              <div class="icon">
                <img src="@/assets/mobile/v0.1/images/mobil_product_xc/man.png" alt="管理创新" />
              </div>
              <div class="txt">
                <h3 class="fnt_20"><b>告别缓“慢”</b></h3>
                <p>
                  道可维斯·数智文档革命性的业务集成调度软件中台，开启企业“三多四跨”离散文档流转“高速公路”，体验文档协作的极致快捷。
                </p>
              </div>
            </li>
          </ul>
          <div class="swiper-pagination"></div>
        </div>
        
        
      </div>
      <!-- PC：改变，从现在开始 -->
      <div class="EBC-intro-list pcshow">
        <ul class="clearfix swiper-wrapper" style="flex-wrap: nowrap">
          <li class="swiper-slide" style="margin: 0;">
            <div class="icon">
              <img src="@/assets/mobile/v0.1/images/mobil_product_xc/san.png" alt="技术领先" />
            </div>
            <div class="txt">
              <h3 class="fnt_20"><b>消除“散”乱</b></h3>
              <p>
                道可维斯创新性的数智文档采集器，通过自动抓取方式跨系统的单据与附件文档，替代员工快速实现离散文档的集中整合。
              </p>
            </div>
          </li>
          <li class="swiper-slide" style="margin: 0;">
            <div class="icon">
              <img src="@/assets/mobile/v0.1/images/mobil_product_xc/lou.png" alt="安全可信" />
            </div>
            <div class="txt">
              <h3 class="fnt_20"><b>预防“错”误</b></h3>
              <p>
                道可维斯·数智文档基于AI大数据技术的业务规则关联校验机器人，以90%以上的纠错准确率帮助员工和领导释放工作压力。
              </p>
            </div>
          </li>
          <li class="swiper-slide" style="margin: 0;">
            <div class="icon">
              <img src="@/assets/mobile/v0.1/images/mobil_product_xc/cuo.png" alt="应用丰富" />
            </div>
            <div class="txt">
              <h3 class="fnt_20"><b>杜绝遗“漏”</b></h3>
              <p>
                道可维斯·数智文档协作规则配置器，通过协作场景文档关联类型定义规则，帮助员工和领导杜绝协作文档提交和审阅遗漏。
              </p>
            </div>
          </li>
          <li class="swiper-slide" style="margin: 0;">
            <div class="icon">
              <img src="@/assets/mobile/v0.1/images/mobil_product_xc/man.png" alt="管理创新" />
            </div>
            <div class="txt">
              <h3 class="fnt_20"><b>告别缓“慢”</b></h3>
              <p>
                道可维斯·数智文档革命性的业务集成调度软件中台，开启企业“三多四跨”离散文档流转“高速公路”，体验文档协作的极致快捷。
              </p>
            </div>
          </li>
        </ul>
        <div class="swiper-pagination"></div>
        <p class="connectus" :style="{ 'margin-top': '40px', 'margin-bottom': '8px', 'text-align': 'center' }">
          <a class="huoqu_more" href="javascript:;" @click="openRegisterPage('联系我们')">即刻联系我们，获取免费咨询</a>
        </p>
      </div>

    </div>


    <div class="anli1 EASC-anli">
      <slider class="anli-slider mobielPhone" ref="slider" :options="options" @slide="slide" @tap="onTap" @init="onInit">
        <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img class="shadow" src="@/assets/default/v0.1/images/caiwu/shadow.png" alt="数智赋能，建设世界一流司库管理体系" />
              <img class="sw_bg" src="@/assets/companyImgs/特变电工背景.png" alt="数智赋能，建设世界一流司库管理体系" />
              <div class="anli_txt">
                <div>
                  <img src="@/assets/mobile/v0.1/images/mobil_product_xc/logo-xh.png" alt="数智赋能，建设世界一流司库管理体系"
                    :style="{ width: '30%' }" />
                  <div class="fnt_36 anli_tit">特变拆图</div>
                  <div class="fnt_20">
                    以AI智能识别技术为主，人工校验为辅的智能拆图工具，对PDF图纸进行智能能识别，提取每张图纸的明细栏，汇总生成产品BOM表，支持一键导出。降低人工重复操作，提高BOM源头数据采集的准确性和高效性。
                  </div>
                  <a href="javascript:;" class="anli_more" @click="openCaseDetail('沈阳特变')">
                    案例详情
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slideritem>
        <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img class="shadow" src="@/assets/default/v0.1/images/caiwu/shadow.png" alt="数智赋能，建设世界一流司库管理体系" />
              <img class="sw_bg" src="@/assets/mobile/v0.1/images/mobil_product_xc/caishenghuo1.jpg"
                alt="数智赋能，建设世界一流司库管理体系" />
              <div class="anli_txt">
                <div>
                  <img src="@/assets/companyImgs/logo/彩生活logo_white.png" alt="数智赋能，建设世界一流司库管理体系"
                    :style="{ width: '40%' }" />
                  <div class="fnt_36 anli_tit">彩生活电子档案</div>
                  <div class="fnt_20">
                    通过与OA、EAS系统对接，打通信息壁垒，实现电子会计档案的自动归档，节省人力劳动，免去纸张打印，减少纸张耗材。通过业务关系，实现电子档案自动勾稽关联，充分检查电子档案完整性，强化风险管控，提高档案管理的合规性，规避税务审计风险。
                  </div>
                  <a href="javascript:;" class="anli_more" @click="openCaseDetail('彩生活')">
                    案例详情
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slideritem>
        <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img class="shadow" src="@/assets/default/v0.1/images/caiwu/shadow.png" alt="数智赋能，建设世界一流司库管理体系" />
              <img class="sw_bg" src="https://www.zsjr.com/upfiles/onepage/202203/1647232856839.jpg"
                alt="数智赋能，建设世界一流司库管理体系" />
              <div class="anli_txt">
                <div>
                  <img src="https://www.zsjr.com/templates/dist/img/logo_wc.png" alt="数智赋能，建设世界一流司库管理体系"
                    :style="{ width: '40%' }" />
                  <div class="fnt_36 anli_tit">洁柔签收</div>
                  <div class="fnt_20">
                    e企签收系统主要实现了签收功能，帮助JR企业提高了签收的效率，根据拍照签收确认收入，不需要等几天纸质回单再确认收入。满足了国家会计制度的要求，实现了收入确认从出库改为签收，实现了及时确认收入。
                  </div>
                  <a href="javascript:;" class="anli_more" @click="openCaseDetail('洁柔')">
                    案例详情
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slideritem>
        <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <img class="shadow" src="@/assets/default/v0.1/images/caiwu/shadow.png" alt="数智赋能，建设世界一流司库管理体系" />
              <img class="sw_bg" src="@/assets/mobile/v0.1/images/mobil_product_xc/yindeqiti.jpg"
                alt="数智赋能，建设世界一流司库管理体系" />
              <div class="anli_txt">
                <div>
                  <img src="@/assets/companyImgs/logo/盈德气体logo_white.png" alt="数智赋能，建设世界一流司库管理体系"
                    :style="{ width: '40%' }" />
                  <div class="fnt_36 anli_tit">盈德气体电子影像</div>
                  <div class="fnt_20">
                    电子影像中台解决方案将文档转为非结构化数据，深度集成多个系统，完成多个系统的高效协同，实现文档数据的实时共享，帮助企业改善业务流程，提高业务效率。
                  </div>
                  <a href="javascript:;" class="anli_more" @click="openCaseDetail('盈德气体')">
                    案例详情
                  </a>
                </div>
              </div>
            </div>
          </div>
        </slideritem>
      </slider>
    </div>

    

    <!-- 客户案例 -->
    <div class="anli-logo">
      <!-- <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img
              class="shadow"
              src="@/assets/default/v0.1/images/caiwu/shadow.png"
              alt="数智赋能，建设世界一流司库管理体系"
            />
            <img
              class="sw_bg"
              src="@/assets/mobile/v0.1/images/mobil_product_xc/sass02.png"
              alt="数智赋能，建设世界一流司库管理体系"
            />
            <div class="anli_txt">
              <div>
                <img
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/logo-xh.png"
                  alt="数智赋能，建设世界一流司库管理体系"
                  :style="{ width: '141px' }"
                />
                <div class="fnt_36 anli_tit">文档整合助力财务信息化转型</div>
                <div class="fnt_20">
                  基于道可维斯的文档数字化智能软件中台，完了共享中心的革命性的数字建设，实现“票据文件整理、数据采集、业财审核利用、凭证整理、归档管理等”全链条数管控与效率提升。
                </div>
              </div>
            </div>
          </div>
        </div>
      </slideritem>
    </slider>

    <!- - 客户案例 -->
      <div class="anli">
        <!-- <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img
              class="shadow"
              src="@/assets/default/v0.1/images/caiwu/shadow.png"
              alt="数智赋能，建设世界一流司库管理体系"
            />
            <img
              class="sw_bg"
              src="@/assets/mobile/v0.1/images/mobil_product_xc/sass02.png"
              alt="数智赋能，建设世界一流司库管理体系"
            />
            <div class="anli_txt">
              <div>
                <img
                  src="@/assets/mobile/v0.1/images/mobil_product_xc/logo-xh.png"
                  alt="数智赋能，建设世界一流司库管理体系"
                  :style="{ width: '141px' }"
                />
                <div class="fnt_36 anli_tit">文档整合助力财务信息化转型</div>
                <div class="fnt_20">
                  基于道可维斯的文档数字化智能软件中台，完了共享中心的革命性的数字建设，实现“票据文件整理、数据采集、业财审核利用、凭证整理、归档管理等”全链条数管控与效率提升。
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="anli_prev"></div>
        <div class="anli_next"></div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="w1440">
        <div class="anli_logo clearfix">
          <img
            src="@/assets/mobile/v0.1/images/mobil_product_xc/case.png"
            alt="<span style='color:red'>[freemarker标签异常，请联系网站管理员]</span>"
          />
        </div>
      </div>
       -->
        <!-- 这些行业领导品牌选择了我们 -->
        <div class="anli_logos clearfix" :style="{ 'margin-top': '20px', 'margin-bottom': '20px' }">
          <div class="title" :style="{ padding: '5px 0' }">
            <h3 class="fnt_32">这些行业领导品牌选择了我们</h3>
            <!-- <img
              class="sw_bg"
              src="@/assets/mobile/v0.1/images/mobil_product_xc/case.png"
              alt="数智赋能，建设世界一流司库管理体系"
            /> -->
            <!-- logo墙 -->
            <div class="EASC-anli">
              <slider class="anli-slider" ref="slider" :options="options" @slide="slide" @tap="onTap" @init="onInit">
                <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
                  <!-- <img
                    class="sw_bg"
                    src="@/assets/mobile/v0.1/images/mobil_product_xc/case.png"
                    alt="数智赋能，建设世界一流司库管理体系"
                  /> -->
                  <img v-if="offsetWidth > 1024" class="sw_bg" src="@/assets/images/logowall1_1.png"
                    alt="数智赋能，建设世界一流司库管理体系" />
                  <img v-if="offsetWidth < 1024" class="sw_bg" src="@/assets/images/logowall3_1.png"
                    alt="数智赋能，建设世界一流司库管理体系" />
                </slideritem>
                <slideritem class="swiper-container" :style="{ 'white-space': 'normal', 'text-align': 'left' }">
                  <!-- <img
                    class="sw_bg"
                    src="@/assets/mobile/v0.1/images/mobil_product_xc/case.png"
                    alt="数智赋能，建设世界一流司库管理体系"
                  /> -->
                  <img v-if="offsetWidth > 1024" class="sw_bg" src="@/assets/images/logowall1_2.png"
                    alt="数智赋能，建设世界一流司库管理体系" />
                  <img v-if="offsetWidth < 1024" class="sw_bg" src="@/assets/images/logowall3_2.png"
                    alt="数智赋能，建设世界一流司库管理体系" />
                </slideritem>
              </slider>
            </div>
          </div>
        </div>
      </div>

      <!-- 服务 -->
      <!-- <div class="starting">
        <div class="w1440">
          <div class="fnt_32 starting_tit" v-if="offsetWidth>700">无论您的三多四跨现状如何复杂，无论您处于世界的哪一个角落 <br/> 只要您需要，道可维斯专家顾问服务都会在第一时间 <br/> 和您一起探讨最合适您的解决方案</div>
          <div class="fnt_32 starting_tit" v-else>道可维斯·数智文档配套服务</div>
          <div
            class="fnt_16 starting_sub"
            :style="{ 'margin-top': '20px', 'font-weight': '700' }"
            v-if="offsetWidth<700"
          >
            伙伴服务
          </div>
          <img
            v-if="offsetWidth<700"
            src="@/assets/mobile/v0.1/images/mobil_product_xc/huoban.png"
            alt=""
          />
          <div
            class="fnt_16 starting_sub"
            :style="{ 'margin-top': '20px', 'font-weight': '700' }"
            v-if="offsetWidth<700"
          >
            客户服务
          </div>
          <img
            src="@/assets/mobile/v0.1/images/mobil_product_xc/kehu.png"
            alt=""
            v-if="offsetWidth<700"
          />
          <img
            src="../../assets/images/kehufuwu.png"
            alt=""
            v-if="offsetWidth>700"
          />
        </div> -->
      <!-- <p class="connectus" :style="{'margin-bottom': '8px','text-align':'center'}">
            <a class="huoqu_more" href="javascript:;" @click="openRegister">即刻联系我们，获取免费咨询</a>
          </p> -->
    </div>
    <!-- 服务 -->
    <div class="starting">
      <div class="w1440">
        <div class="fnt_32 starting_tit" v-if="offsetWidth > 700">无论您的三多四跨现状如何复杂，无论您处于世界的哪一个角落 <br />
          只要您需要，道可维斯专家顾问服务都会在第一时间 <br /> 和您一起探讨最合适您的解决方案</div>
        <div class="fnt_32 starting_tit" v-else>道可维斯·数智文档配套服务</div>

        <div class="fnt_32 starting_tit" v-if="offsetWidth < 700">道可维斯服务配套</div>
        <!-- <div
            class="fnt_16 starting_sub"
            :style="{ 'margin-top': '20px', 'font-weight': '700' }"
            v-if="offsetWidth<700"
          >
            伙伴服务
          </div>
          <img
            v-if="offsetWidth<700"
            src="@/assets/mobile/v0.1/images/mobil_product_xc/huoban.png"
            alt=""
          /> -->
        <div class="fnt_16 starting_sub" :style="{ 'margin-top': '20px' }" v-if="offsetWidth < 700">
          无论您的三多四跨现状如何复杂，无论您处于世界的哪一个角落，只要您需要，道可维斯专家顾问服务都会在第一时间和您一起探讨最合适您的解决方案
        </div>
        <img src="@/assets/mobile/v0.1/images/mobil_product_xc/kehu.png" alt="" v-if="offsetWidth < 700" />
        <img src="../../assets/images/kehufuwu.png" alt="" v-if="offsetWidth > 700" />
      </div>
      <!-- 常见问题折叠面板 -->
      <!-- 常见问题 -->
      <div class="answer" v-if="offsetWidth < 700 && $route.name != 'guidepage'">
        <div class="w1440">
          <h3 class="fnt_24">常见问题</h3>
          <el-collapse accordion style="text-align: left;">
            <!-- <el-collapse-item title="Q1 道可维斯·e企系列产品如何收费？">
            <div>软件按年订阅付费，部分功能消耗流量计费。</div>
          </el-collapse-item>
          <el-collapse-item title="Q2 软件如何交付与部署？">
            <div>简化流程：设计简洁直观的操作流程；</div>
            <div>
              在私有云的模式下，由道可维斯提供软件部署安装与交付的服务；
            </div>
            <div>
              在公有云模式下，没有交付和部署的问题，但公有云我们还没上线，预计还要1个月后。
            </div>
          </el-collapse-item>
          <el-collapse-item title="Q3 可以试用吗，在哪里试用？">
            <div>
              可以的，我们云上有体验环境，联系我们试用后，我们给您提供个试用账号，并有专业售前顾问教您使用。
            </div>
          </el-collapse-item>
          <el-collapse-item title="Q4 产品限制用户数量吗？">
            <div>不限制，企业管理员在后台可以配置用户。</div>
          </el-collapse-item> -->

            <el-collapse-item name="1">
              <span class="collapse-title" slot="title">道可维斯·e企系列产品如何收费？</span>
              <div>软件按年订阅付费，部分功能消耗流量计费。</div>
            </el-collapse-item>
            <el-collapse-item name="2">
              <span class="collapse-title" slot="title">软件如何交付与部署？</span>
              <div>简化流程：设计简洁直观的操作流程；</div>
              <div>
                在私有云的模式下，由道可维斯提供软件部署安装与交付的服务；
              </div>
              <div>
                在公有云模式下，没有交付和部署的问题，但公有云我们还没上线，预计还要1个月后。
              </div>
            </el-collapse-item>
            <el-collapse-item name="3">
              <span class="collapse-title" slot="title">可以试用吗，在哪里试用？</span>
              <div>
                可以的，我们云上有体验环境，联系我们试用后，我们给您提供个试用账号，并有专业售前顾问教您使用。
              </div>
            </el-collapse-item>
            <el-collapse-item name="4">
              <span class="collapse-title" slot="title">产品限制用户数量吗？</span>
              <div>不限制，企业管理员在后台可以配置用户。</div>
            </el-collapse-item>
          </el-collapse>
          <!-- <ul>
          <li class="">
            <div class="fnt_18 ans_tit" :class="{ans_act: isOpenQuestion == 1}" @click="isOpenQuestion=1">道可维斯·e企系列产品如何收费？</div>
            <div class="ans_des" :class="{isBlock: isOpenQuestion == 1}">
              <p>软件按年订阅付费，部分功能消耗流量计费。<br /></p>
            </div>
          </li>
          <li class="">
            <div class="fnt_18 ans_tit" :class="{ans_act: isOpenQuestion == 2}" @click="isOpenQuestion=2">软件如何交付与部署？</div>
            <div class="ans_des" :class="{isBlock: isOpenQuestion == 2}">
              <p>
                在私有云的模式下，由道可维斯提供软件部署安装与交付的服务；
                在公有云模式下，没有交付和部署的问题，但公有云我们还没上线，预计还要1个月后<br />
              </p>
            </div>
          </li>
        </ul> -->
        </div>
      </div>
      <!--视频弹窗-->
      <div class="vio" :class="{ vio_flx: isOpenVideo }" @click="isOpenVideo = false">
        <div class="video">
          <i class="el-icon-circle-close closeVideo" @click="isOpenVideo = false"></i>
          <!-- <svg
          t="1655108403808"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2122"
          width="30"
          height="30"
        >
          <path
            d="M512 1024C229.248 1024 0 794.752 0 512S229.248 0 512 0s512 229.248 512 512-229.248 512-512 512z m0-572.330667L300.629333 240.213333a42.538667 42.538667 0 0 0-60.16 0.213334 42.410667 42.410667 0 0 0-0.213333 60.16L451.669333 512 240.213333 723.370667a42.538667 42.538667 0 0 0 0.213334 60.16 42.410667 42.410667 0 0 0 60.16 0.213333L512 572.330667l211.370667 211.413333a42.538667 42.538667 0 0 0 60.16-0.213333 42.410667 42.410667 0 0 0 0.213333-60.16L572.330667 512l211.413333-211.370667a42.538667 42.538667 0 0 0-0.213333-60.16 42.410667 42.410667 0 0 0-60.16-0.213333L512 451.669333z"
            fill="#fff"
            p-id="2123"
          ></path>
        </svg> -->
          <video ref="videofile" id="video1" controls autoplay v-if="isOpenVideo">
            <!-- poster="@/assets/mobile/v0.1/images/mobil_product_xc/download01.png" -->
            <source type="video/mp4" src="@/assets/example1.mp4" />
            您的浏览器不支持Video标签。
          </video>
        </div>
      </div>

      <div id="videoPopup1">
        <div class="video-box">
          <video src="" controls autoplay disablepictureinpicture="" controlslist="nodownload "
            oncontextmenu="return false" muted>
            This browser does not support HTML5 video
          </video>
          <div class="close">
            <span class="iconfont">&#xe61a;</span>
          </div>
        </div>

        <div id="videoPopup1">
          <div class="video-box">
            <video src="" controls autoplay disablepictureinpicture="" controlslist="nodownload "
              oncontextmenu="return false" muted="">
              This browser does not support HTML5 video
            </video>
            <div class="close">
              <span class="iconfont">&#xe61a;</span>
            </div>
          </div>
        </div>
        <!-- 有疑问？我们将通过以下渠道为您解答 S -->
        <!-- <div class="index-yiwen">
      <div class="wrapper">
        <div class="info">
          <h2 class="fnt_32">如果想进一步了解道可维斯产品或解决方案</h2>
          <p class="fnt_20">
            您可以通过以下方式联系我们，我们将推荐适合您需求的产品，或指派专业的销售顾问为您定制解决方案。
          </p>
        </div>
        <div class="img">

        </div>
      </div>
    </div> -->
        <!-- 评分 -->
        <!-- <div class="product_xc_pingfen">
      <div class="w1440 clearfix">
        <div class="mr">
          <div class="product_pingfen_title">该页面是否满足您的需求？</div>
          <div class="product_pingfen_zan">
            <img src="@/assets/default/v0.1/images/product/zan.png" />
          </div>
          <div class="product_pingfen_cai">
            <img src="@/assets/default/v0.1/images/product/cai.png" />
          </div>
        </div>
        <div class="dianzan">
          <div class="title">感谢！</div>
          <div class="des">您的认可和好评，将激励我们更好地提升网站体验</div>
        </div>
        <div class="diancai">
          <div class="title">您认为我们还存在哪些不足和需要改进之处？</div>
          <div class="diancai_choose">
            <div class="sub_title">请从以下选项中选择（可多选）：</div>
            <form onsubmit="return false">
              <div class="checkbox_list">
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen1"
                    value="内容不够清晰，不方便寻找或了解产品价值"
                  />
                  <label for="pingfen1"
                    >内容不够清晰，不方便寻找或了解产品价值</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen2"
                    value="行业方案陈旧，未契合真实业务场景的需要"
                  />
                  <label for="pingfen2"
                    >行业方案陈旧，未契合真实业务场景的需要</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen3"
                    value="产品体验（体验/试用/购买）不顺畅"
                  />
                  <label for="pingfen3">产品体验（体验/试用/购买）不顺畅</label>
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen4"
                    value="不能快捷找到服务和支持的入口"
                  />
                  <label for="pingfen4">不能快捷找到服务和支持的入口</label>
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen5"
                    value="页面设计混乱，操作方式不符合日常习惯"
                  />
                  <label for="pingfen5"
                    >页面设计混乱，操作方式不符合日常习惯</label
                  >
                </div>
                <div class="checkbox_item">
                  <input
                    type="checkbox"
                    id="pingfen6"
                    value="存在技术问题（链接失效/显示问题/页面错误等）"
                  />
                  <label for="pingfen6"
                    >存在技术问题（链接失效/显示问题/页面错误等）</label
                  >
                </div>
                <div class="checkbox_item">
                  <input type="checkbox" id="pingfen7" value="其他" />
                  <label for="pingfen7">其他</label>
                </div>
              </div>
              <div class="input_qt">
                <input type="text" name="" id="" value="" />
                <span></span>
              </div>
              <span class="tixing"></span>
              <button>提交反馈</button>
            </form>
          </div>
        </div>
        <div class="after_diancai">
          <div class="title">感谢！</div>
          <div class="des">您的反馈和建议，将激励我们更好的提升网站体验</div>
        </div>
      </div>
    </div> -->

        <!-- 移动端图片放大 -->
        <div id="outerdiv" style="
            position: fixed;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.9);
            z-index: 2;
            width: 100%;
            height: 100%;
            display: none;
          ">
          <div id="innerdiv" style="position: absolute">
            <img id="bigimg" style="width: 100%" src="" />
          </div>
        </div>
        <div class="mobil_footer" style="text-align: left">
          <div class="footer-right">
            <!-- <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">为什么选择金蝶</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/company"
              class="footer-right-link-list"
            >
              了解金蝶</a
            >
            <a
              href="https://www.kingdee.com/company/honor"
              class="footer-right-link-list"
            >
              金蝶荣誉</a
            >
            <a
              href="https://investor.kingdee.com/"
              class="footer-right-link-list"
              rel="nofollow"
            >
              投资者关系</a
            >
            <a
              href="https://www.kingdee.com/success-stories/"
              class="footer-right-link-list"
            >
              客户案例</a
            >
          </ul>
        </div>
        <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">产品与服务</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/products/financial_cloud.html"
              class="footer-right-link-list"
            >
              财务云</a
            >
            <a
              href="https://www.kingdee.com/products/taxcloud.html"
              class="footer-right-link-list"
            >
              税务云</a
            >
            <a
              href="https://www.kingdee.com/products/hcm.html"
              class="footer-right-link-list"
            >
              人力云</a
            >
            <a
              href="https://www.kingdee.com/products/cosmic_platform.html"
              class="footer-right-link-list"
            >
              可组装企业级PaaS</a
            >
            <a
              href="https://www.kingdee.com/products/cosmic.html"
              class="footer-right-link-list"
            >
              大型企业EBC</a
            >
          </ul>
        </div>
        <div class="footer-right-link-box">
          <div class="footer-link-title-box">
            <p class="footer-right-link-title">解决方案</p>
          </div>
          <ul class="footer-link-detailed">
            <a
              href="https://www.kingdee.com/solutions/manufacture.html"
              class="footer-right-link-list"
              >制造业</a
            >
            <a
              href="https://www.kingdee.com/solutions/service.html"
              class="footer-right-link-list"
              >服务业</a
            >
            <a
              href="https://www.kingdee.com/solutions/traffic_logistics.html"
              class="footer-right-link-list"
              >电子</a
            >
            <a
              href="https://www.kingdee.com/solutions/real_estate.html"
              class="footer-right-link-list"
              >房地产</a
            >
            <a
              href="https://www.kingdee.com/solutions/architecture.html"
              class="footer-right-link-list"
              >建筑</a
            >
          </ul>
        </div> -->

            <!-- <div class="footer-link-mobil">
          <a href="tel:4008-830-830">咨询热线: 400-6789-189</a>
        </div>
        <div class="footer-link-bottom">

          <div class="footer-link-bottombq">
            <span>CopyRight © 2009-2029 Docuvix All Rights Reserved</span>
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
          <div class="footer-link-bottomba">
            <a
              href="http://www.beian.gov.cn"
              target="_blank"
              rel="noopener noreferrer"
              >粤ICP备13024405号</a
            >
          </div>
        </div> -->
          </div>
        </div>
        <!-- 咨询小窗 -->
        <div class="zhezhao"></div>
        <div class="consultcon">
          <div class="consulttit">
            <span class="kfgirl"><img src="@/assets/mobile/v0.1/images/mobil_header/kfgirl.png" />金蝶客服</span>
            <span class="kfdownarrow" id="J_kfclose"><img
                src="@/assets/mobile/v0.1/images/mobil_header/kfdownarrow.png" /></span>
          </div>
          <iframe src="" class="kfiframe"></iframe>
        </div>
        <el-drawer title="我是标题" :visible.sync="drawer" direction="btt" :with-header="false" class="drawer">
          <p class="msg" :style="{ 'font-weight': '700' }">
            请先选择您的提问类型：<el-select v-model="value1" placeholder="请选择" @change="changeQuestion">
              <el-option v-for="(item, index) in optionsQues" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </p>

          <p class="msg">
            {{ questionMessage }}
          </p>
          <p class="tip">(工作人员将在24小时内，以微信公众号形式回复您)</p>
          <div class="input">
            <el-input type="textarea" resize="none" v-model="question"></el-input>
            <el-button class="submit" type="primary">提交</el-button>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script>
// require("@/assets/mobile/v0.1/js/jquery-1.11.1.min.js");
// require("@/assets/mobile/v0.1/js/header.js");
import { slider, slideritem } from "vue-concise-slider";
import product from "./product.vue";
import companyProfile from "./companyProfile.vue";
import caseCompany from "./case.vue";
import agreement from "./agreement.vue";
export default {
  name: "GuidePage",
  components: {
    slider,
    slideritem,
    product,
    companyProfile,
    agreement,
    caseCompany,
  },
  data() {
    return {
      value1: "使命",
      optionsQues: [
        {
          label: "使命",
          value: "使命",
          msg: "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!",
        },
        {
          label: "产品",
          value: "产品",
          msg: `感谢您对道可维斯的产品提出宝贵意见!`,
        },
        {
          label: "伙伴政策",
          value: "伙伴政策",
          msg: "感谢您对道可维斯的伙伴政策-一次分享三年分成提出宝贵意见!",
        },
        {
          label: "案例名录",
          value: "案例名录",
          msg: "感谢您对道可维斯的案例名录提出宝贵意见!",
        },
      ],
      imgItems: [{
        id: "1",
        imageUrl: "",
        isDisable: 1,
        text1: null,
        text2: null,
        timeFrom: "",
        timeTo: "",
      }],
      isOpenVideo: false,
      isOpenQuestion: 0, //是否打开了问题
      isActive: false, //控制顶部与底部是否显示
      isShowquesetion: true,
      questionMessage: "",
      checked: false,
      question: "", //提问的问题
      drawer: false, //提问框
      someList: [
        {
          html: "slide1",
          style: {
            background: "#fff",
          },
        },
        {
          html: "slide2",
          style: {
            background: "#4bbfc3",
          },
        },
        {
          html: "slide3",
          style: {
            background: "#7baabe",
          },
        },
      ],
      //Slider configuration [obj]
      options: {
        pagination: true,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300, // Sliding speed
        currentPage: 0,
        // timingFunction: 'ease', // Sliding mode
        // loop: false, // Infinite loop
        // autoplay: 0 // Auto play[ms]
      },
      offsetWidth: document.body.offsetWidth,//页面宽度
      direction: "btt",//弹出框位置
      productList:[
        {
          seriesName:"X-SMART ECM 系列",
          seriesDesc:"全电子档案 | 会计电子档案 | 电子影像 | 数智档案库 | 运单签收",
          path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
          softwareList:[
            {
              name:"全电子档案",
              desc:"规范企业档案管理，提高档案利用率，助力业务提速，降低业务风险。构建业务链档案、集团电子档案，实物档案等多种档案管理场景。",
              path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
              imageList:[
                require("@/assets/products/qdzda1.png"),
                require("@/assets/products/qdzda2.png"),
                require("@/assets/products/qdzda3.png"),
                require("@/assets/products/qdzda4.png"),
              ]
            },
            {
              name:"会计电子档案",
              desc:"满足财政局与档案局对会计档案得要求，保障会计档案完整性，减少税务风险和用档效率。",
              path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
              imageList:[
                require("@/assets/products/kjdzda1.png"),
                require("@/assets/products/kjdzda2.png"),
                require("@/assets/products/kjdzda3.png"),
              ]
            },
            {
              name:"电子影像",
              desc:"提高报销效率，降低发票风险。实现员工自助报销、智能审票、财务共享场景等场景。",
              path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
              imageList:[
                require("@/assets/products/dzyx1.png"),
                require("@/assets/products/dzyx2.png"),
                require("@/assets/products/dzyx3.png"),
              ]
            },
            {
              name:"数智档案室",
              desc:"满足部门与档案室对电子档案与实物档案的需要，轻松方便有效对档案进行有条有序地管理。",
              path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
              imageList:[
                require("@/assets/products/szdas1.png"),
                require("@/assets/products/szdas2.png"),
                require("@/assets/products/szdas3.png"),
              ]
            },
            {
              name:"运单签收",
              desc:"实现厂商与卖场间的送货签收单据的无纸化与物流签收管理，加快入账凭据回传，提高收入确认效率。",
              path:"https://eqzh.beyondsky.love/officialWebsite/#/archivesIndex",
              imageList:[
                require("@/assets/products/eqqs1.png"),
                require("@/assets/products/eqqs2.png"),
              ]
            }
          ]
        },
        {
          seriesName:"e企智汇 系列",
          seriesDesc:"文档工具箱 | 企业+个人云空间 | 文档共享宝 | 场景应用服务集",
          path:"https://ysm.beyondsky.love/eqzh/#/pages/home/newHome",
          softwareList:[
            {
              name:"文档工具箱+个人云空间",
              desc:"众多文档处理工具，文档云存储，快速上传下载，随时在线查看与工作伙伴共享，助力办公效率。",
              path:"https://ysm.beyondsky.love/eqzh/#/pages/home/newHome",
              imageList:[
                require("@/assets/products/eqzh1.jpg"),
                require("@/assets/products/eqzh2.jpg"),
                require("@/assets/products/eqzh3.jpg"),
              ]
            },
            {
              name:"文档共享宝",
              desc:"事项链条管理，让每个工作事项的文档传递、沟通与上下文都能清晰记录下来，方便随时追溯与复盘。",
              path:"https://ysm.beyondsky.love/eqzh/#/pages/home/newHome",
              imageList:[
                require("@/assets/products/wdgxb1.png"),
                require("@/assets/products/wdgxb2.png"),
                require("@/assets/products/wdgxb3.png"),
              ]
            },
            {
              name:"场景应用服务集",
              desc:"多种应用服务，包括运维到期提醒、目标管理、销售管理等，轻松让公司业务井然有序。",
              path:"https://ysm.beyondsky.love/eqzh/#/pages/home/newHome",
              imageList:[
                require("@/assets/products/yyfw1.png"),
                require("@/assets/products/yyfw2.png"),
                require("@/assets/products/yyfw3.png"),
              ]
            }
          ]
        },
        {
          seriesName:"云扫描 系列",
          seriesDesc:"平板扫描仪 | e企拆图 ",
          path:"https://ysm.beyondsky.love/documentSharing/#/ysmIndex",
          softwareList:[
            {
              name:"平板扫描仪",
              desc:"新一代扫描体验，无需连接电脑，超高速脱机扫描，随扫随拿，提高办公效率。",
              path:"https://ysm.beyondsky.love/documentSharing/#/ysmIndex",
              imageList:[
               require("@/assets/products/smy1.jpg"),
              ]
            },
            {
              name:"e企拆图",
              desc:"自动化提取纸质表格与图片表格数据，代替人工重复大量的机械工作，让工作效率提醒。适用于拆图成BOM，外来单据的录入等场景。",
              imageList:[
                require("@/assets/products/eqct1.png"),
                require("@/assets/products/eqct2.png"),
              ]
            }
          ]
        }
      ],
      currentProduct:{},
    };
  },
  filters:{
    aa(value){
      console.log(value);
      return value;
    }
  },
  created() {
    this.GetRotationPos()
    if (!sessionStorage.getItem('operationLog')) {
      if (!this.$route.query.data) {
        let operationLog = "公众号-官网"
        sessionStorage.setItem('operationLog', operationLog)
      }
    }
    console.log(this.offsetWidth)
    //判断是PC端还是/手机端
    let system = {};
    system.pingtai =
      /(Win32|Win16|WinCE|Mac68K|MacIntel|MacIntel|MacPPC|Linux mips64)/i.test(
        navigator.platform
      );
    if (system.pingtai) {
      //pc
      console.log("pc端", system.pingtai);
      this.systemPingtai = true;
      // sessionStorage.clear('userInfo')
      // sessionStorage.clear('userDetialInfo')
      // let code = this.getUrlCode()
      // console.log(code)
      // if(code !="" && code != undefined){
      //   this.LoginByScanCode(code)
      // }

      if (
        sessionStorage.getItem("userInfo") &&
        sessionStorage.getItem("userInfo") != ""
      ) {
        this.nickname = JSON.parse(sessionStorage.getItem("userInfo")).nickname;
        console.log(this.nickname);
      }
      // else{
      //   this.getCode();
      //   this.LoginByScanCode(this.code);
      // }
    } else {
      //手机
      this.systemPingtai = false;
      const userAgent = navigator.userAgent.toLowerCase();
      //是否微信浏览器
      if (userAgent.includes('micromessenger')) {//是
        if (
          !sessionStorage.getItem("userInfo") ||
          sessionStorage.getItem("userDetialInfo") == ""
        ) {
          this.getCode();
        }
      }
    }

    this.currentProduct = this.productList[0]
  },
  methods: {
    //获取轮播图配置
    GetRotationPos() {
      this.$api.beyoudsky.GetRotationPos({}).then((res) => {
        if (res.data.errorCode == "-99") {
          this.$message({
            message: `获取数据失败！请联系管理员，失败原因为：${res.data.errorText}`,
            type: 'warning',
          })
        }
        this.imgItems = res.data.data
        console.log(this.imgItems);
      })
    },
    //点击banner

    changBanner(item) {
      console.log(item);
      const today = new Date();
      const startTime = new Date(this.$utils.removeT(item.timeFrom));
      const endTime = new Date(this.$utils.removeT(item.timeTo));
      console.log(today,startTime,endTime,this.$utils.removeT(item.timeFrom),this.$utils.removeT(item.timeTo));
      if (today >= startTime && today <= endTime) {
        console.log("当前时间在指定范围内");
        this.$router.push({
          name: 'activity',
          query:{
            name:'',
            from:'数字化发票',
            id:item.id,
          },
        })
      } else {
        console.log("当前时间不在指定范围内");
        this.$message({
            message: `当前时间不在活动范围内`,
            type: 'warning',
          })
      }

    },
    getCode() {
      // let data = {
      //   openid: "o-W8KjxozWkgsA3K1l8lYXk5iGcc",
      //   nickname: "",
      //   sex: 0,
      //   province: "",
      //   city: "",
      //   country: "",
      //   headimgurl:
      //     "https://thirdwx.qlogo.cn/mmopen/vi_32/HrbY1o4wvbcY2OhZEhH4BWp41GHuDuNKia1rNVJuBQH5ybIXVQnE2uj5Ef9Bok6A1WJWhkIia4vZPuic2lLYlqlJA/132",
      //   privilege: [],
      //   unionid: "",
      // };
      // sessionStorage.setItem("userInfo", JSON.stringify(data));
      // console.log(JSON.parse(sessionStorage.getItem("userInfo")));
      // 非静默授权，第一次有弹框
      this.code = "";
      // if (this.systemPingtai) {
      //   if (this.page == "shopproductdetails") {
      //     var local = "http://docuvix.com";
      //   } else {
      var local = "http://docuvix.com";
      //   }
      // } else {
      //   var local = window.location.href;
      // }
      this.appid = "wx27e60c547a5ab724"; //公众号里有自己查，不会找的查一下百度
      this.code = this.getUrlCode().code; // 截取code
      console.log(11111111,this.code);

      if (this.code == null || this.code === "") {
        // 如果没有code，则去请求
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          this.appid +
          "&redirect_uri=" +
          encodeURIComponent(local) +
          "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
        sessionStorage.setItem("code", this.code);
      } else {
        console.log(sessionStorage.getItem('from'));
        let param = {
          Code: this.code,
          source: sessionStorage.getItem('from') ? sessionStorage.getItem('from') : "官网",
        };
        //把code存到session
        sessionStorage.setItem("code", this.code);
        this.$api.beyoudsky.GetWXScopeInfo(param).then((res) => {
          if (res.data.errorCode == "1") {
            console.log("GetWXScopeInfo", res);
            let openid = JSON.parse(res.data.data).openid;
            console.log(openid);
            let param = { WXID: openid };
            this.GetUserPoByWX(param);
          }
          if (res.data.errorCode == "-99") {
            if (res.data.errorText.includes('获取微信信息失败')) {
              window.location.href =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
              this.appid +
              "&redirect_uri=" +
              encodeURIComponent(local) +
              "&response_type=code&scope=snsapi_userinfo#wechat_redirect";
              sessionStorage.setItem("code", this.code);
              return;
            }
            alert(res.data.errorText);
            return;
          }
          sessionStorage.setItem("userInfo", res.data.data);
        });
        // 你自己的业务逻辑

        // this.GetWXUserInfo();
      }
    },
    getUrlCode() {
      var url = location.search;
      console.log(url);
      this.winUrl = url;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      console.log(theRequest);
      return theRequest;
    },
    //根据微信openID获取用户信息
    GetUserPoByWX(data) {
      this.$api.beyoudsky.GetUserPoByWX(data).then((res) => {
        if (res.data.errorCode == "1") {
          console.log(res);
          sessionStorage.setItem("userDetialInfo", res.data.data);
        } else {
          alert(res.data.errorText);
        }
      });
    },
    //打开案例详情页面
    openCaseDetail(title) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + "案例详情";
        this.$utils.AddAction(name)
      }
      this.$router.push({
        path: "/caseDetail",
        query: {
          title
        }
      });
    },
    openRegisterPage(type) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + type;
        this.$utils.AddAction(name)
      }
      this.$router.push({ path: "/register", query: { type: "预约演示", DemandType: "需求" } });
    },
    //打开注册页面
    openRegister() {
      if (!sessionStorage.getItem('userInfo') || sessionStorage.getItem('userInfo') == '') {
        this.$message({
          message: '暂未登录，请先登录',
          type: 'warning',
          duration: 1000,
          onClose: () => {
            this.$parent.scanlogin1()
          }
        })
        return
      }
      this.$router.push({ path: "/register", query: { type: "预约演示", DemandType: "需求" } });
    },
    changeQuestion(val) {
      console.log(val);
      console.log(88);
      let data = this.optionsQues.filter((item) => {
        return item.value == val;
      });
      this.questionMessage = data[0].msg;
    },
    qidai(type) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + type;
        this.$utils.AddAction(name)
      }
      alert("敬请期待!");
    },
    scrollChange() {
      // console.log("滚动高度", window.pageYOffset);

      // console.log("距离顶部高度", this.$refs.menu.getBoundingClientRect().top);
      if (window.pageYOffset < 100) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },
    //打开产品页面
    openProductView(val) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + val + '了解详情';
        this.$utils.AddAction(name)
      }
      this.$store.commit("changeType", ["产品", val]);
      console.log(this.$store.state.questionMsg);
      this.$router.push({
        path: "/allProduct",
        query: {
          data: val,
        },
      });
    },
    openProductPath(item){
      console.log(item);
      if (item.path) {
        window.open(item.path)
      }
      
    },
    //打开提问框
    openQuestion(type) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + type;
        this.$utils.AddAction(name)
      }
      if (this.offsetWidth < 1024) {
        this.drawer = true;
      } else {
        this.drawer = false
      }
    },
    //打开分成协议
    openAgreement() {
      this.$router.replace({ name: "sharingAgreement" });
    },
    //产品页的提示框
    questionOpen(data) {
      console.log(data);
      this.drawer = true;
      this.questionMessage = `感谢您对道可维斯的${data}产品提出宝贵意见!`;
      this.question = "";
    },
    //滑动时触发的事件
    slide(data) {
      switch (data.currentPage) {
        case 0:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的使命-用文档整合链接你我他提出宝贵意见!";
          this.question = "";
          this.currentProduct = this.productList[0]
          break;
        case 1:
          this.isShowquesetion = true;
          this.questionMessage =
            "感谢您对道可维斯的伙伴政策-一次分享三年分成提出宝贵意见!";
          this.question = "";
          this.currentProduct = this.productList[1]
          break;
        case 2:
          this.isShowquesetion = false;
          this.currentProduct = this.productList[2]

          break;
        case 3:
          this.isShowquesetion = true;
          this.questionMessage = "感谢您对道可维斯的案例名录提出宝贵意见!";
          this.question = "";
          break;
      }
      // console.log(data);
    },
    onInit(data) {
      // console.log(data);
    },
    onTap(data) {
      // console.log(data);
    },
    openVideo(type) {
      if (this.$route.query.data == undefined) {
        let name = '官网' + type;
        this.$utils.AddAction(name)
      }
      this.isOpenVideo = true;
      // 视频自动播放
      setTimeout(() => {
        // console.log(this.$refs.videofile);
        this.$refs.videofile.play();
      }, 200);
    },
  },
  mounted() {
    // 滚动条的获取
    // window.addEventListener("scroll", this.scrollChange, true);
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src = "./assets/mobile/v0.1/js/header.js";
    // document.body.appendChild(script);
  },
};
</script>
<style lang="less">
@import "../../assets/mobile/v0.1/css/new-common.css";
@import "./assets/mobile/v0.1/css/iconfont.css";
@import "./assets/mobile/v0.1/css/animate.min.css";
@import "./assets/mobile/v0.1/css/swiper.min.css";
@import "./assets/mobile/v0.1/css/jquery.mCustomScrollbar.css";
@import "./assets/mobile/v0.1/css/header.css";
@import "./assets/mobile/v0.1/css/footer.css";

@import "./assets/mobile/v02/css/mo_product_gather.css";
@import "./assets/mobile/v02/css/mo_product_pages.css";

@import "../../assets/mobile/v0.1/css/mo_score.css";

// 相关产品swiper-dot样式
.rlv-product {
  .slider-pagination-bullets {
    // margin-top: 30px;
    bottom: -4px !important;

    .slider-pagination-bullet {
      background-color: #007aff !important;
    }

    .slider-pagination-bullet-active {
      background-color: #007aff !important;
    }
  }
}

// 案例swiper-dot样式
.EASC-anli {
  .slider-pagination-bullets {
    padding: 0 20px;
    text-align: center !important;
    bottom: 10px !important;

    .slider-pagination-bullet {
      background-color: #393b3c !important;
    }

    .slider-pagination-bullet-active {
      background-color: #007aff !important;
    }
  }
}

// 移动端首页案例swiper-dot样式
.anli1 {
  .slider-pagination-bullets {
    padding: 0 20px;
    text-align: left !important;

    .slider-pagination-bullet {
      background-color: #fff !important;
    }

    .slider-pagination-bullet-active {
      background-color: #fff !important;
    }
  }
}

.anli_logos .slider-pagination-bullets {
  text-align: center;
  position: relative;
  bottom: -2px !important;

  .slider-pagination-bullet {
    background-color: #007aff;
  }

  .slider-pagination-bullet-active {
    background-color: #007aff;
  }
}
</style>
<style lang="less" scoped>
.fnt_20 {
  font-size: 14px;
}

.fnt_32 {
  font-size: 22px;
}

.fnt_36 {
  font-size: 24px;
}

.manage {
  padding: 50px 0;
}

.anli .swiper-slide .sw_bg {
  object-fit: cover;
  width: 100%;
}

.w1440 {
  padding-left: 20px !important;
  padding-right: 20px !important;
  box-sizing: border-box;

  .slider-wrapper {
    flex-wrap: wrap !important;
  }
}

.slider-wrapper {
  flex-wrap: wrap !important;
}

.mobilheader_box {
  display: none;

  .mobil_header {
    background-color: #fff;

    .features-box {
      .gm {
        margin-right: 0;
      }
    }
  }
}

.anli-logo {
  .title {
    margin: 0 auto;

    img {
      padding: 0 20px;
    }
  }
}

.connectus {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .mobilheader_box {
    display: block;
  }

  // .shadow {
  //   display: none;
  // }
  .anli_txt {
    // top: auto;
    // transform: translate(-50%, 0);
    // bottom: 75px;
    // padding: 0 20px;

    .anli_more {
      font-size: 14px;
      color: #fff;
    }
  }

  .anli_tit {
    margin: 20px 0 10px;
  }

  .starting {
    padding: 30px 0;
  }

  .starting_sub {
    margin: 10px auto 20px;
    color: #575d6c;
  }

  .answer {
    padding: 30px 0;

    h3 {
      font-size: 24px;
    }
  }

  .fn_24 {
    font-size: 24px;
  }

  // 案例详情
  .EASC-anli .swiper-slide {
    .sw_bg {
      height: 520px;
    }

    .anli_txt {
      width: 96%;
      // padding:0 10px;
      padding-right: 20px;
      margin-top: 50px;
    }
  }

  .EASC-anli .swiper-slide::after {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80%;
    background-image: linear-gradient(transparent, black);
  }
}

.mobile-lv3nav .product-small:not(:first-child),
.mobile-lv3nav .product-high:not(:first-child),
.mobile-lv3nav .product-large:not(:first-child),
.mobile-lv3nav .product-pass:not(:first-child) {
  margin-top: 0.5rem;
}

.el-collapse {
  border-top: none;
}

// 常见问题图标
.collapse-title {
  flex: 1 0 90%;
  margin-left: 10px;
  order: 1;

  .el-collapse-item__header {
    flex: 1 0 auto;
    order: -1;

  }
}

.answer /deep/ .el-collapse-item__header {
  height: 58px;
  line-height: 58px;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/deep/.el-collapse-item__content {
  font-size: 14px;
  padding-left: 32px;
}

.isBlock {
  display: block;
  // transition: all 0.5s;
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  width: 50%;
  margin-bottom: 20px;

  .txt {
    padding: 0;

    p {
      color: #575d6c;
      font-size: 14px;
      line-height: 20px;
    }

    h3 {
      margin-bottom: 6px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 12px;
    }
  }
}

.EBC-intro-list .swiper-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  white-space: "normal";
  text-align: "left";
}

.EBC-intro-list .swiper-wrapper .swiper-slide {
  // float: left;
  width: 23%;

  .swiper-container-horizontal {
    span {
      background-color: red !important;
    }
  }

  .slider-pagination-bullet-active {
    background-color: red !important;
  }
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon {
  max-width: 85px;
  margin: 0 auto 20px;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
  max-width: 100%;
  transition: all 0.5s;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt {
  padding: 0;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
  text-align: center;
  margin-bottom: 12px;
  color: #252b3a;
}

.EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
  color: #575d6c;
  line-height: 28px;
  font-size: 16px;
}

.pcshow {
  display: none;
}

.rlv-product {
  .slider-item {
    p {
      font-size: 16px;
    }
  }
}

@media screen and(min-width: 1024px) {
  .connectus {
    display: block;

    .huoqu_more {
      display: inline-block;
      width: 300px;
      color: #fff;
    }
  }

  .connectus:hover {
    cursor: pointer;
  }

  .mobielPhone {
    display: none;
  }

  .pcshow {
    display: block;
  }

  .mobilheader_box {

    // display: none;  
    .mobil_header {
      background-color: #fff;
      height: 1rem !important;
      line-height: 1rem !important;

      .features-box {
        top: 50% !important;
        transform: translateY(-0.7rem) !important;
      }
    }
  }

  .product_xc_banner {

    // .banner_phone{
    //   background:url('../../assets/images/shouyepc.jpg') !important; 
    // }0328
    .text {
      text-align: left !important;
      left: 135px !important;

      .fnt_42 {
        font-size: 42px;
        font-weight: bold;
      }

      .banner-text {
        margin-top: 10px;
        margin-bottom: 0px !important;
        font-size: 22px;
      }
    }

    .banner-text .fnt_18 {
      font-size: 22px !important;

    }

    /deep/li {
      width: 60px;
      height: 20px;
      margin: 10px 6px !important;

      button {
        width: 60px !important;
      }
    }

    .block {
      cursor: pointer;

      .el-carousel {
        height: 100%;
      }

      /deep/ .el-carousel__arrow--left {
        // top: 70px;
        // left: -13px;
        // font-size: 15px;
        color: #fff;
      }

      //右箭头
      /deep/ .el-carousel__arrow--right {
        // top: 70px;
        right: 36px;
        // font-size: 15px;
        color: #fff;
      }
    }
  }

  .manage {
    padding: 50px 135px 50px 135px !important;

    .fnt_32 {
      font-size: 32px;
    }

    .w1440 {
      padding: 0 !important;
    }

    .manage_sum {
      padding: 0 !important;

      .pcp {
        text-indent: 30px;
        line-height: 28px;
      }
    }
  }

  .rlv-product {
    padding: 50px 135px 50px 135px;
    height: 710px;

    .w1440 {
      padding: 0 !important;

      .fnt_32 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 42px;
      }

      .pcp1 {
        font-size: 18px;
        margin-bottom: 44px;
      }

    }

    .EBC-intro-list .swiper-wrapper {
      margin-top: 145px;

      p {
        text-align: center;
      }
    }

    .cont-1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cont-1-div {
        margin: 5px;
        width: 31% !important;
        height: 100%;
        position: relative;
        overflow: hidden;
        margin-bottom: 25px !important;

        .hover-content {
          margin: 0px;
          transition: .4s ease-out;
          position: absolute;
          left: 0;
          bottom: -40%;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .4);

          .hover-content-title {
            font-size: 22px;
            // font-weight:bold;
            margin-left: 20px;
            margin-bottom: 5px;
          }

          .epm_des {
            margin-left: 20px;
            font-size: 14px !important;
          }

          .el-button {
            position: absolute;
            left: 5%;
            bottom: 10%;
          }
        }

      }

      .cont-1-div:not(:nth-child(4n)) {
        margin-right: calc(4% / 3);
      }

      .cont-1-div:hover {
        cursor: pointer;
        transition: .4s ease-out;

        .hover-content {
          left: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 1);

          .hover-content-title {
            margin: 20px 0 20px 20px
          }
        }
      }
    }
  }

  .anli1 {
    padding: 50px 145px;
    // min-height: 770px!important;
    box-sizing: border-box;

    .fnt_36 {
      font-size: 36px;
    }

    .fnt_20 {
      font-size: 20px;
    }

    .title {
      font-size: 32px;
      font-weight: 800;
      margin-bottom: 42px;
      text-align: center;

      .sw_bg {
        width: 50% !important;
      }
    }

    .title1 {
      color: rgb(87, 93, 108);
      font-size: 18px;
      text-align: center;
      margin-bottom: 40px;
    }

    .anli_txt {
      left: 857px;
      top: 285px;
    }

    .answer .fnt_24 {
      margin-top: 20px;
      font-size: 24px;
    }

    .EBC-intro-list .swiper-wrapper {
      margin-top: 145px;

      p {
        text-align: center;
      }
    }

    .cont-1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      img {
        width: 26px !important;
        height: 26px !important;
      }

      .cont-1-div {
        margin: 5px 0;
        width: 24% !important;
        height: 100%;
        position: relative;
        overflow: hidden;
        // margin-bottom: 25px!important;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(53, 52, 52, 0.5);

        .hover-content {
          margin: 0px;
          transition: .4s ease-out;
          position: absolute;
          left: 0;
          bottom: -50px;
          height: 100%;
          width: 100%;
          background: rgba(255, 255, 255, .4);

          // .hover-content-title{
          //   font-size: 22px;
          //   // font-weight:bold;
          //   margin-left:20px;
          //   margin-bottom: 5px;
          // }
          .epm_des {
            // margin-left: 20px;
            padding: 20px;
            font-size: 14px !important;
          }

          .el-button {
            position: absolute;
            left: 5%;
            bottom: 10%;
          }
        }

        .hover-content-title1 {
          font-size: 16px;
          font-weight: bold;
          height: 30px;
          // line-height: 30px;
          padding-top: 6px;
          // margin-left:36px;
          padding-left: 43px;
        }

        .hover-content-title {
          // font-size: 16px;
          // font-weight: bold;
          // height: 30px;
          // // line-height: 30px;
          // padding-top: 6px;
          // // margin-left:36px;
          // padding-left: 43px;
          margin-bottom: 5px;
          margin-left: 20px;

          span {
            font-size: 12px;
            display: inline-block;

            width: 50px;
            height: 20px;
            margin-right: 2px;
            line-height: 20px;
            text-align: center;
            font-weight: normal;
            background: lightgray;
          }
        }

      }

      .cont-1-div:not(:nth-child(4n)) {
        margin-right: calc(4% / 3);
      }

      .cont-1-div:hover {
        cursor: pointer;
        transition: .4s ease-out;

        .hover-content {
          left: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 1);

          .hover-content-title {
            margin: 20px 0 20px 20px
          }
        }
      }
    }
  }

  .anli-logo {
    background: #e1e9f3;
    padding: 50px 145px;

    .anli {
      .title {
        .fnt_32 {
          font-size: 32px;
          font-weight: 800;
          // margin-bottom: 42px;
          text-align: center;
        }

      }

      /deep/.slider-pagination-bullets {
        text-align: center !important;
        bottom: 0px !important;
      }

      /deep/.slider-pagination-bullet {
        background: #252b3a;
      }
    }

    .anli_logos img {
      width: 90%;
    }

    // .EASC-anli{
    //   height: 700px!important;
    // }
  }


  .starting {
    padding-bottom: 20px;
    background: none;

    .w1440 {
      padding: 50px 0;

    }

    img {
      width: 70% !important;
    }

    .fnt_32 {
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      margin-bottom: 50px;
    }
  }

  .answer {
    .w1440 {
      padding: 0 !important;
    }

    .el-collapse {
      padding: 0 !important;
    }

    .fnt_24 {
      font-size: 32px;
      font-weight: 800;
      text-align: center;
      // text-align: left;
      margin: 50px 0;
    }

    // .el-collapse-item{
    // text-align: left;
    // }
  }

  .el-collapse {
    padding: 0 135px;
  }

}

.mobielPhone .swiper-slide a .text .epm_des {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .rlv-product {
    padding: 30px 0;
    height: auto;

    .w1440 {

      // 一行三个
      .cont {
        // height: 600px;
        height: auto;
      }
    }
  }

  .EBC-intro-list {
    overflow: hidden;
  }

  .EBC-intro-list .swiper-wrapper {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap !important;
    justify-content: space-between;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide {
    width: 48%;
    margin-bottom: 20px;

    .txt {
      text-align: center;
    }
  }

  .rlv-product .cont .swiper-wrapper {
    // display: inline-block !important;
    height: 600px;
    margin-bottom: 10px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon {
    margin-bottom: 10px;
    max-width: 48px;
    text-align: center;
    height: 37px;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .icon img {
    width: auto;
    max-height: 100%;
    object-fit: contain;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt h3 {
    margin-bottom: 6px;
    font-weight: bold;
  }

  .EBC-intro-list .swiper-wrapper .swiper-slide .txt p {
    font-size: 14px;
    line-height: 20px;
  }
}


.swiper-container-horizontal .slider-pagination-bullet-active {
  background: #007aff none repeat scroll 0 0 !important;
}

.swiper-container-horizontal .slider-pagination-bullets {
  bottom: -3px;
}

.video {
  .closeVideo {
    font-size: 40px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 66;
    cursor: pointer;
  }

  #video1 {
    width: 100%;
    object-fit: cover;
  }
}

#guidepage {
  width: 100%;

  .product_xc_banner {
    .text {
      .h1 {
        margin-top: 1.2rem;
      }

      .banner-text {
        margin-bottom: 1.2rem;
      }
    }

    li {
      margin: 0 6px;
    }
  }

  .drawer {
    /deep/.el-drawer__open .el-drawer.btt {
      border-radius: 10px 10px 0 0;
      padding: 10px;
      height: auto !important;
    }

    .msg {
      line-height: 30px;
      font-size: 18px;

      .el-select {
        width: 120px;
        height: 30px;

        /deep/.el-input--suffix .el-input__inner {
          height: 30px;
        }

        /deep/ .el-input__icon {
          line-height: 30px;
        }
      }
    }

    .tip {
      line-height: 25px;
      font-size: 14px;
    }

    .input {
      text-align: center;
      height: calc(100% - 120px);
      padding: 5px 0px !important;

      /deep/.el-textarea__inner {
        height: 100% !important;
      }

      .submit {
        margin-top: 10px;
        width: 85px;
        height: 40px;
        padding: 12px;
        margin-left: 5px;
        font-size: 20px;
      }
    }

    .el-textarea {
      width: 100%;
      height: calc(100% - 80px);
    }
  }

  .shadow {
    display: none;
  }
}

.wordColor {
  color: #0360c3;
  font-weight: 700;
}



// 移动端11个产品展示
.rlv-product .w1440 .cont {
  // .slider-touch{
  // width: 375px;
  // }

  // slide
  .cont-swiper {
    // width: 100%;
    height: auto !important;

    // height: 200px;
    // display: flex;
    // align-content: flex-start;
    // align-items: start;
    // slideitem
    .swiper-slide {
      width: 100%; //0329
      height: auto !important;
      // display: flex;
      // align-content: space-evenly;


      .epm_des {
        font-size: 14px;
      }
    }
  }
}

// body,html{
//   max-width: 100%;
//   overflow-x: hidden;
// }
</style>